import React, { FC } from "react";
import Layout from "../components/layout";
import Banner from "../components/profile/banner";
import ProfileDescription from "../components/profile/details/profileDescription";
import ProfileTitle from "../components/profile/details/profileTitle";
import SocialMedia from "../components/profile/details/socialMedia";
import ProfileContainer from "../components/profile/profileContainer";
import { useProfile } from "../hooks/profile/useProfile";
import Loading from "../components/loading/loading";
import SEO from "../components/seo";
import { useProfilePic } from "../hooks/profile/useProfilePic";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MobileProfileDropdown from "../components/common/dropdown/mobileProfileDropdown";

interface ProfilePageProps {
  profileId: string;
}
const ProfilePage: FC<ProfilePageProps> = ({ profileId }) => {
  const [user, loading, error] = useProfile(profileId);
  const profilePic = useProfilePic(user?.address);

  if (loading) {
    return (
      <Layout>
        <SEO title="Profile" description="Check out my profile" />
        <div className="flex flex-col h-full place-content-center">
          <div className="mt-16 mx-auto">
            <Loading size={32} />
          </div>
        </div>
      </Layout>
    );
  } else if (!user || error) {
    return (
      <Layout>
        <SEO title="Profile" />
        <div className="flex flex-col w-full h-full justify-center">
          <h1 className="text-white text-center">
            There is no profile associated with this username/address
          </h1>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div
        className="flex flex-col space-y-24 md:space-y-32"
        style={{ minHeight: "100vh" }}
      >
        <Banner userId={user.address} />
        <div className="h-full grid grid-cols-12 md:gap-24 px-8 md:px-24 mt-0 md:mt-24 w-full">
          <div className="flex flex-col space-y-8 md:space-y-24 md:pb-48 col-span-12 md:col-span-3">
            <div className="flex flex-row w-full">
              <div className="relative min-w-1/4 md:min-w-1/2 max-w-86 md:max-w-196 pb-32 md:pb-96">
                <div className="absolute w-80 md:w-196 h-80 md:h-196 md:h-bg-dark-background border-8 md:border-12 border-dark-background bg-clip-content rounded-full transform -translate-y-2/3 md:-translate-y-1/2">
                  <img
                    className="h-full w-full rounded-full object-cover bg-dark-background"
                    src={profilePic}
                  />
                </div>
              </div>
              <div className="flex flex-row w-full justify-end md:hidden pr-8">
                <MobileProfileDropdown
                  dropdownIcon={
                    <MoreVertIcon className="fill-current text-dark-white-64" />
                  }
                  user={user}
                />
              </div>
            </div>

            <div className="flex flex-col px-8 space-y-16 md:space-y-24">
              <ProfileTitle user={user} />
              <ProfileDescription
                description={user.description}
                joined={user.createdAt._seconds}
              />
              <div className="hidden md:block">
                <SocialMedia user={user} />
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-grow w-full h-full col-span-12 md:col-span-9 pt-24 md:pt-0">
            <ProfileContainer user={user} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProfilePage;
